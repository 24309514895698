import React, {Component} from "react";
import ReactModal from 'react-modal';
import { connect } from "react-redux";
import { saveLogin } from "../../redux/actions/loginActions";
import { fetchUser } from "../../redux/actions/userActions";

import {
  Grommet,
  Box,
  Header,
  Sidebar,
  Footer,
  Heading,
  Paragraph,
  Text,
  Anchor, 
  Button, 
  CheckBox,
  Form,
  FormField,
  TextInput,
  RadioButtonGroup,
  RangeInput,
  Select,
  TextArea,
  Collapsible,
  Nav,
  Carousel,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Image,
  Icons,
  Grid,
  Tabs,
  Tab,
  DataTable,
  Avatar,
  Layer,
  Stack,
  Drop,
  DropButton,
  ResponsiveContext, 
} from 'grommet';
import { Menu, FormClose, Notification, User, Cart, Mail, Contact, StatusGoodSmall, FormAdd, FormSubtract, Trash } from 'grommet-icons';

import { Config } from '../../Config';
import { apiconn } from '../../apiconn'; //axios connectors are here

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var size = '';

class MainFooter extends Component {
  constructor(props) {
    super(props);
    
    let lang = "id";
    if(this.props.lang !== undefined && this.props.lang.lang !== undefined && this.props.lang.lang == "id" || this.props.lang.lang == "en"){
      lang = this.props.lang.lang;
    }
    
    this.state = {
      lang: lang,
      width: 0,
      height: 0,
    }
    
    size = this.props.size;
    
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    
  }
  
  componentDidMount(){
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  
  renderFooterMenu(){
    return (
      <>
      <Box direction="row" gap="medium" fill="horizontal" flex="grow">
        <Box width="28%" gap="10px">
          <Heading level="4" margin="none">{this.state.lang == "en" ? "Our Services" : "Layanan Kami"}</Heading>
          <Anchor href="/about-us" label={this.state.lang == "en" ? "About Us" : "Tentang Kami"} />
          <Anchor href="/articles" label={this.state.lang == "en" ? "Articles" : "Artikel"} />
          <Anchor href="/inspiration" label={this.state.lang == "en" ? "Inspiration" : "Inspirasi"} />
        </Box>
        <Box width="32%" gap="10px">
          <Heading level="4" margin="none">{this.state.lang == "en" ? "Shop" : "Belanja"}</Heading>
          <Anchor href="/products" label={this.state.lang == "en" ? "Our Product" : "Produk Kami"} />
          <Anchor href="/confirm-payment" label={this.state.lang == "en" ? "Payment Confirmation" : "Konfirmasi Pembayaran"} />
          <Anchor href="/my-account" label={this.state.lang == "en" ? "My Account" : "Akun Saya"} />
        </Box>
        <Box width="40%" className="footer-newsletter" gap="small">
          <Heading level="4" margin="none">{this.state.lang == "en" ? "Subscribe to our newsletter here" : "Bergabung dengan newsletter kami"}</Heading>
          <Box>
            <Text>{this.state.lang == "en" ? "Stay tune with our news and" : "Dapatkan berita terkini dan promo"}</Text>
            <Text>{this.state.lang == "en" ? "interesting promos!" : "menarik dari kami!"}</Text>
          </Box>
          <Form>
            <Box direction="row" gap="3%" wrap="true">
              <Box width="59%">
                <TextInput placeholder="Email" />
              </Box>
              <Button primary label={this.state.lang == "en" ? "Sign up" : "Bergabung"} type="submit" />
            </Box>
          </Form>
        </Box>
      </Box>
      <Box direction="row" align="end" gap="medium" fill="horizontal" flex="grow">
        <Box width="28%" gap="10px">
          <Heading level="4" margin="none">{this.state.lang == "en" ? "Help" : "Bantuan"}</Heading>
          <Anchor href="/faq" label="FAQ" />
          <Anchor href="/terms-conditions" label={this.state.lang == "en" ? "Terms & Conditions" : "Syarat & Ketentuan"} />
        </Box>
        <Box width="32%" gap="10px">
          <Heading level="4" margin="none">{this.state.lang == "en" ? "Contact Us" : "Hubungi Kami"}</Heading>
          <Box>
            <Anchor href="mailto:helloupspace@gmail.com">
              <Box direction="row" align="center" gap="small">
                <Image src="icon-email.png" width="20px" />
                <Text>helloupspace@gmail.com</Text>
              </Box>
            </Anchor>
          </Box>
          <Box>
            <Anchor href="https://api.whatsapp.com/send?phone=62816667679&text=Hai%20Up%20Space%20saya%20ingin%20bertanya...">
              <Box direction="row" align="center" gap="small">
                <Image src="icon-whatsapp.png" width="20px" />
                <Text>0816667679</Text>
              </Box>
            </Anchor>
          </Box>
        </Box>
        <Box width="40%" direction="row"  gap="small">
          <Box width="40px" height="40px">
            <Anchor href="https://www.instagram.com/upspace.id" target="_blank">
              <Image src="icon-instagram.png" fit="contain" fill />
            </Anchor>
          </Box>
          <Box width="40px" height="40px">
            <Anchor href="https://www.tiktok.com/@upspace_indonesia" target="_blank">
              <Image src="icon-tiktok.png" fit="contain" fill />
            </Anchor>
          </Box>
          <Box width="40px" height="40px">
            <Anchor href="https://www.facebook.com/upspace.id/" target="_blank">
              <Image src="icon-facebook.png" fit="contain" fill />
            </Anchor>
          </Box>
        </Box>
      </Box>
      </>
    )
  }
  
  renderFooterMenuMobile(){
    return (
      <>
        <Box className="footer-newsletter" gap="small"  margin={{bottom:"30px"}}>
          <Heading level="3" margin="none">{this.state.lang == "en" ? "Subscribe to our newsletter here" : "Bergabung dengan newsletter kami"}</Heading>
          <Box margin={{bottom:"5px"}}>
            <Text>{this.state.lang == "en" ? "Stay tune with our news and" : "Dapatkan berita terkini dan promo"}</Text>
            <Text>{this.state.lang == "en" ? "interesting promos!" : "menarik dari kami!"}</Text>
          </Box>
          <Form>
            <Box direction="row" gap="3%" wrap="true">
              <Box width="60%">
                <TextInput placeholder="Email" />
              </Box>
              <Button className="button" primary label={this.state.lang == "en" ? "Sign up" : "Bergabung"} type="submit" />
            </Box>
          </Form>
        </Box>
        <Box gap="30px">
          <Box gap="medium">
            <Heading level="4" margin="none">{this.state.lang == "en" ? "Our Services" : "Layanan Kami"}</Heading>
            <Anchor href="/about-us" label={this.state.lang == "en" ? "About Us" : "Tentang Kami"} />
            <Anchor href="/articles" label={this.state.lang == "en" ? "Articles" : "Artikel"} />
            <Anchor href="/inspiration" label={this.state.lang == "en" ? "Inspiration" : "Inspirasi"} />
          </Box>
          <Box gap="medium">
            <Heading level="4" margin="none">{this.state.lang == "en" ? "Shop" : "Belanja"}</Heading>
            <Anchor href="/products" label={this.state.lang == "en" ? "Our Product" : "Produk Kami"} />
            <Anchor href="/confirm-payment" label={this.state.lang == "en" ? "Payment Confirmation" : "Konfirmasi Pembayaran"} />
            <Anchor href="/my-account" label={this.state.lang == "en" ? "My Account" : "Akun Saya"} />
          </Box>
        </Box>
      </>
    )
  }
  
  renderFooterMenuRightMobile(){
    return (
      <>
        <Box gap="30px" fill>
          <Box gap="medium">
            <Heading level="4" margin="none">{this.state.lang == "en" ? "Contact Us" : "Hubungi Kami"}</Heading>
            <Box>
                <Anchor href="#">
                  <Box direction="row" align="center" gap="small">
                    <Image src="icon-email.png" width="20px" />
                    <Text>helloupspace@gmail.com</Text>
                  </Box>
                </Anchor>
              </Box>
              <Box>
                <Anchor href="#">
                  <Box direction="row" align="center" gap="small">
                    <Image src="icon-whatsapp.png" width="20px" />
                    <Text>0816667679</Text>
                  </Box>
                </Anchor>
              </Box>
          </Box>
          <Box gap="medium">
            <Heading level="4" margin="none">{this.state.lang == "en" ? "Help" : "Bantuan"}</Heading>
            <Anchor href="/faq" label="FAQ" />
            <Anchor href="/terms-conditions" label={this.state.lang == "en" ? "Terms & Conditions" : "Syarat & Ketentuan"} />
          </Box>
        </Box>
        <Box width="50px" gap="medium">
          <Box width="50px" height="50px">
            <Anchor href="https://www.instagram.com/upspace.id" target="_blank">
              <Image src="icon-instagram.png" fit="contain" fill />
            </Anchor>
          </Box>
          <Box width="50px" height="50px">
            <Anchor href="https://www.tiktok.com/@upspace_indonesia" target="_blank">
              <Image src="icon-tiktok.png" fit="contain" fill />
            </Anchor>
          </Box>
          <Box width="50px" height="50px">
            <Anchor href="https://www.facebook.com/upspace.id/" target="_blank">
              <Image src="icon-facebook.png" fit="contain" fill />
            </Anchor>
          </Box>
        </Box>
      </>
    )
  }
  
  renderCopyrightText(){
    return (
      <Text>Up Space &copy; 2022 All rights reserved</Text>
    )
  }
  
  render(){
    return (
      <>
        { /* {( size !== 'small') ? ( */}
        {this.state.width > 960 ? (
          <Footer className="page-footer" pad={{top:"70px"}}>
            <Box className="container" flex="grow">
              { /*<Box direction="row" gap="7%">
                <Box width="11%" className="logo" flex={false}>
                  <Image src="logo-footer.png" />
                </Box>
                <Box width="82%" align="start" gap="medium" flex="grow">
                  {this.renderFooterMenu()}
                </Box>
              </Box>*/ }
              <Box className="copyright" align="center" pad={{vertical:"medium"}} margin={{top:"large"}}>
                {this.renderCopyrightText()}
              </Box>
            </Box>
          </Footer>
        ): (
          <Footer className="page-footer" pad={{top:"xlarge"}}>
            <Box className="container" flex="grow">
              { /*
              <Box margin={{bottom:"30px"}} gap="30px" flex="grow">
                <Box align="center">
                  <Image src="logo-footer.png" width="100px" />
                </Box>
                {this.renderFooterMenuMobile()}
              </Box>
              <Box direction="row" align="end" justify="between" flex="grow">
                {this.renderFooterMenuRightMobile()}
              </Box>*/ }
              <Box className="copyright" align="center" pad={{vertical:"medium"}} margin={{top:"large"}}>
                {this.renderCopyrightText()}
              </Box>
            </Box>
          </Footer>
        )}
      </>
    )
  }
}
  
const mapStateToProps = (state, ownProps) => {
  return {
    login: state.loginData,
    user: state.userData,
    lang: state.langData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveLogin: (id_user, api_token) => {
      dispatch(saveLogin(id_user, api_token));
    },
    fetchUser: userdata => {
      dispatch(fetchUser(userdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainFooter); 
