import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AppRoute from "./helpers/AppRoute";

import { DefaultLayout } from "./layouts";
//import "./assets/scss/style.scss";
import "./assets/App.css";
import { Preloader } from "./components";
import ScrollToTop from "./helpers/scroll-top";

const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Login"));
const Signup = lazy(() => import("./pages/Signup"));
// const EditProfile = lazy(() => import("./pages/EditProfile"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const Products = lazy(() => import("./pages/Products"));
const Category = lazy(() => import("./pages/Category"));
const Faq = lazy(() => import("./pages/Faq"));
const Product = lazy(() => import("./pages/Product"));
const GarageSale = lazy(() => import("./pages/GarageSale"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const Inspiration = lazy(() => import("./pages/Inspiration"));
const Articles = lazy(() => import("./pages/Articles"));
const ArticleID = lazy(() => import("./pages/ArticleID"));
const Checkout = lazy(() => import("./pages/Checkout"));
const ThankYou = lazy(() => import("./pages/ThankYou"));
const MyAccount = lazy(() => import("./pages/MyAccount"));
const MyAddress = lazy(() => import("./pages/MyAddress"));
const MyOrder = lazy(() => import("./pages/MyOrder"));
const MyProfile = lazy(() => import("./pages/MyProfile"));
const MyWarranties = lazy(() => import("./pages/MyWarranties"));
const Invoice = lazy(() => import("./pages/Invoice"));
const ConfirmPayment = lazy(() => import("./pages/ConfirmPayment"));
const TermsConditions = lazy(() => import("./pages/TermsConditions"));
const UpspaceDream = lazy(() => import("./pages/UpspaceDream"));

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Suspense fallback={<Preloader />}>
          <Switch>
            <Route
              path={process.env.PUBLIC_URL + "/"}
              exact
              render={props => <Login {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/login"}
              /*component={Home}*/
              render={props => <Login {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/signup"}
              /*component={Home}*/
              render={props => <Signup {...props} />}
            />
            
          </Switch>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
}

export default App;
