import React, {Component} from "react";
import ReactModal from 'react-modal';
import { connect } from "react-redux";
import { saveLogin } from "../../redux/actions/loginActions";
import { fetchUser } from "../../redux/actions/userActions";
import { increaseQuantityDispatch, decreaseQuantityDispatch, deleteFromCartDispatch } from "../../redux/actions/cartActions";
import { switchLang } from "../../redux/actions/langActions";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/core';

// Import Swiper styles
import 'swiper/swiper.min.css';
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

import {
  Grommet,
  Box,
  Header,
  Sidebar,
  Footer,
  Heading,
  Paragraph,
  Text,
  Anchor, 
  Button, 
  CheckBox,
  Form,
  FormField,
  TextInput,
  RadioButtonGroup,
  RangeInput,
  Select,
  TextArea,
  Collapsible,
  Nav,
  Carousel,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Image,
  Icons,
  Grid,
  Tabs,
  Tab,
  DataTable,
  Avatar,
  Layer,
  Stack,
  Drop,
  DropButton,
  ResponsiveContext, 
} from 'grommet';
import { Menu, FormClose, Notification, User, Cart, Mail, Contact, StatusGoodSmall, FormAdd, FormSubtract, Trash, Search } from 'grommet-icons';

import { Config } from '../../Config';
import { apiconn } from '../../apiconn'; //axios connectors are here

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// install Swiper modules
SwiperCore.use([Autoplay, EffectFade, Navigation, Pagination]);

function numberFormat(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

var size = '';
var headertype = '';
var pagetitle = (<></>);
var pagebanner = "";
var pagebannermobile = "";
var homebanner = [];

var apiheader = "";

class MainHeader extends Component {
  constructor(props) {
    super(props);
    
    let lang = "id";
    if(this.props.lang !== undefined && this.props.lang.lang !== undefined && this.props.lang.lang == "id" || this.props.lang.lang == "en"){
      lang = this.props.lang.lang;
    }
    
    this.state = {
      lang: lang,
      open: false,
      layerNav: false,
      layerMobileNotification: false,
      
      width: 0,
      height: 0,
    }
    
    size = this.props.size;
    
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    
    let headerhome = ['home'];
    let headerpage = ['about-us', 'articles', 'category', 'faq', 'garage-sale', 'terms-conditions', 'upspace-dream'];
    let headerplain = ['article-id', 'checkout', 'confirm-payment', 'edit-profile', 'forgot-password', 'inspiration', 'invoice', 'login', 'my-account', 'my-address', 'my-order', 'my-profile', 'my-warranties', 'product', 'products', 'reset-password', 'signup', 'thank-you'];
    if(headerhome.includes(this.props.page))
      headertype = 'home';
    else if(headerpage.includes(this.props.page))
      headertype = 'page';
    else if(headerplain.includes(this.props.page))
      headertype = 'plain';
    
    pagebanner = this.props.pagebanner !== undefined ? this.props.pagebanner : "";
    pagebannermobile = this.props.pagebannermobile !== undefined ? this.props.pagebannermobile : this.props.pagebanner;
    pagetitle = this.props.pagetitle !== undefined ? this.props.pagetitle : (<></>);
    
    homebanner = this.props.homebanner !== undefined ? this.props.homebanner : [];
  }
  
  componentDidMount(){
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    
    this._fetchUserData();
    this.thetimer = setInterval(() => { this._timerFunctions() }, 2500);
  }
  
  doLogout = () => {
      this.props.saveLogin(0, '');
      this.props.history.replace('/login');
    };

  componentWillUnmount(){
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  
  languageSwitch = () => (
    <Box className="language-switch" pad="20px">
      {this.state.lang == "id" ? (
      <Anchor href="javascript:void()" onClick={() => {this.props.switchLang("en"); setTimeout(() => window.location.reload(), 200)}}>
        <Box direction="row" align="center" gap="5px">
          <Image src="flag-en.jpg" width="22px" height="15px" />
          <Text>EN</Text>
        </Box>
      </Anchor>
      ) : (
      <Anchor href="javascript:void()" onClick={() => {this.props.switchLang("id"); setTimeout(() => window.location.reload(), 200)}}>
        <Box direction="row" align="center" gap="5px">
          <Image src="flag-id.jpg" width="22px" height="15px" />
          <Text>ID</Text>
        </Box>
      </Anchor>
      )
      }
    </Box>
  );
  
  dropNotification = () => (
    <Box className="drop-notification" width="medium">
      <Box direction="row" pad="20px" align="center" justify="between">
        <Text weight="bold">Notifications</Text>
        <Button icon={<FormClose color="#757575" />} plain />
      </Box>
      <Box className="drop-notification-list">
        { /* Start Render Notifications  */}
        
        {/*
        <Box className="notification-item" pad={{horizontal:"20px"}} flex="false">
          <Anchor href="#">
            <Box direction="row" align="top" gap="small" pad={{vertical:"20px"}}>
              <StatusGoodSmall color="brand" />
              <Box gap="xsmall" pad={{top:"2px"}}>
                <Text color="brand" className="bold">Confirm your payment</Text>
                <Text size="small" color="#000">Invoice no. #971814861 is waiting to be paid.</Text>
                <Text size="small" color="#737270">03-03-2020 10:46</Text>
              </Box>
            </Box>
          </Anchor>
        </Box>
  
        <Box className="notification-item" pad={{horizontal:"20px"}} flex="false">
          <Anchor href="#">
            <Box direction="row" align="top" gap="small" pad={{vertical:"20px"}}>
              <StatusGoodSmall color="brand" />
              <Box gap="xsmall" pad={{top:"2px"}}>
                <Text color="brand" className="bold">Your order has arrived</Text>
                <Text size="small" color="#000">Invoice no. #971814861 order has arrived at and is received by <strong>John Doe</strong></Text>
                <Text size="small" color="#737270">03-03-2020 10:46</Text>
              </Box>
            </Box>
          </Anchor>
        </Box>
        */ }
        
        { /* End Render Notifications  */}
  
        { /* If there are no notification  */}
        <Box className="notification-item" pad={{horizontal:"20px"}}>
          <Box gap="xsmall" pad={{vertical:"20px"}}>
            <Text>{this.state.lang == "en" ? "No notifications" : "Tidak ada notifikasi"}</Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
  
  toggleCart() {
    this.setState({ open: this.state.open ? false : true });
  }

  closeLayer(){
    this.setState({layerNav: false, layerMobileNotification: false});
  }

  openLayer(){
    this.setState({layerNav: true});
  }
  
  openLayerMobile(){
    this.setState({layerMobileNotification: true});
  }
  
  _timerFunctions(){
      this._fetchUserData();
  }
  
  async _fetchUserData(){
    if(this.props.login.id_user < 1){
        // this.doLogout();
    }
    else {
      apiconn.getApi.get('/web/user/data/'+this.props.login.id_user, apiheader)
        .then((response) => {
          if (response.status >= 200 && response.status <= 202) { //success
            let resp = response.data;
            
            if(resp.deleted == 1){ //logout if user is deleted
                this.doLogout();
            }
            else {
                this.props.fetchUser(resp);
            }
        }
        else if(response.status == 203){ //fail
          let resp = response.data;
          
          //this.props.history.replace("/");
        }
        else{
          //console.log("Request failed");
        }
      })
      .catch(error => {
        if(error !== undefined){
          if(error.response !== undefined){
            if(error.response.status !== undefined){
              if(error.response.status == 401){ //unauthorized
                this.doLogout();
              }
            }
          }
        }
        
        //this._backgroundFetchAttempt();
        });
    }
  }
  
  getCartQty(){
    if(this.props.cart.length == 0 || this.props.cart.length === undefined){
      return 0;
    }
    let cartqty = 0;
    this.props.cart.forEach((dt) => {
      cartqty += dt.quantity;
    });
    return cartqty;
  }
  
  renderShoppingCart(){
    let cartcontents;
    let subtotal = 0;
    let discount = 0;
    let totalqty = 0;
    
    if(this.props.cart.length == 0 || this.props.cart.length === undefined){
      cartcontents = (
        <Box className="shopping-cart-item" alignSelf="start" direction="row" pad="small" wrap={true} flex={false} fill="horizontal">
          <Text size="small">{this.state.lang == "en" ? "No item in your cart." : "Tidak ada barang dalam cart."}</Text>
        </Box>
      );
    } else {
      cartcontents = this.props.cart.map((dt) => {
        totalqty += dt.quantity;
        subtotal += dt.quantity * dt.variant_price;
        let itemobj = {
          id_variant: dt.id_variant,
          variant_name: dt.variant_name,
          variant_price: dt.variant_price
        }
        return (
          <Box className="shopping-cart-item" alignSelf="start" direction="row" pad="small" wrap={true} flex={false}>
            <Box className="cart-item-photo" basis="1/4" flex="grow">
              <Anchor href="javascript:void(0)">
                <Box round="small" overflow="hidden" fill>
                  { dt.variant.id_image == 0 ? (
                    <Image width="100%" src="placeholder.jpg" />
                  ) : (
                    <Image width="100%" src={Config.API_IMG_URL + 'products/' + dt.variant.id_image + '.jpg'} />
                  )
                  }
                </Box>
              </Anchor>
            </Box>
            <Box className="cart-item-info" basis="3/4" pad={{left:"16px"}} gap="5px" flex="grow">
              <Box direction="row" align="top" justify="between">
                <Box>
                  <Anchor href="javascript:void(0)">
                    <Box>
                      <Text className="cart-item-name">{dt.variant_name}</Text>
                    </Box>
                  </Anchor>
                  <Text className="cart-item-price">Rp {numberFormat(dt.variant_price)}</Text>
                  { /*<Text className="cart-item-variant">Colour: Black</Text>*/ }
                </Box>
                <Box>
                  <Anchor onClick={() => this.props.deleteFromCart(itemobj)} className="cart-item-remove" icon={<Trash size="18px" />} />
                </Box>
              </Box>
              <Box className="cart-item-quantity" direction="row" align="center" justify="end" wrap={true}>
                <Button onClick={() => this.props.decreaseQuantity(itemobj)} className="btn-qty" icon={<FormSubtract size="10px" />} /><Text weight="bold" color="#363435" margin={{horizontal: "small"}}>{dt.quantity}</Text><Button onClick={() => this.props.increaseQuantity(itemobj)} className="btn-qty add" icon={<FormAdd size="10px" />} />
              </Box>
            </Box>
          </Box>
        )
      });
    }
    
    return (
      <Collapsible open={this.state.open}>
        <Layer className="shopping-cart-layer" full="vertical" position="right">
          <Card fill>
            <CardHeader direction="row" pad="20px" align="center" justify="between" fill="horizontal" elevation="medium">
              <Box direction="row" align="center" gap="xsmall">
                <Text weight="bold">Cart</Text>
                <Text size="small" color="#b2b2b2">({totalqty} items)</Text>
              </Box>
              <Button icon={<FormClose color="#757575" />} plain onClick={() => this.toggleCart()} />
            </CardHeader>
            <CardBody className="shopping-cart-content">
              <Box pad="small" overflow={{vertical:"auto"}} fill>
  
                
                {cartcontents}
                
              </Box>
            </CardBody>
            <CardFooter direction="column" justify="between">
              <Box className="spacer" />
              <Box pad={{bottom:"medium", horizontal:"medium"}} gap="small" fill="horizontal">
                <Box className="shopping-cart-layer-subtotal" direction="row" align="center" justify="between">
                  <Box direction="row" align="center" gap="xsmall">
                    <Text>Subtotal</Text>
                    <Text size="small" color="#b2b2b2">({totalqty} items)</Text>
                  </Box>
                  <Text>Rp {numberFormat(subtotal)}</Text>
                </Box>
                { /*
                <Box className="shopping-cart-layer-discount" direction="row" align="center" justify="between">
                  <Text>Discount</Text>
                  <Text>-Rp 200.000</Text>
                </Box>
                */ }
                <Box className="shopping-cart-layer-total" direction="row" align="center" justify="between">
                  <Text>Total</Text>
                  <Text>Rp {numberFormat(subtotal - discount)}</Text>
                </Box>
                {totalqty > 0 &&
                <Button primary className="button primary" onClick={() => this.props.history.replace("/checkout")} label="Checkout" fill="horizontal" />
                }
              </Box>
            </CardFooter>
          </Card>
        </Layer>
      </Collapsible>
    )
  }
  
  renderNotificationLayer(){
    
      if(this.state.layerNav){
        return (
          <Layer
            className="mobile-nav-layer"
            full={true}
            onEsc={() => this.closeLayer()}
            position="center"
          >
            <Box className="layer-wrapper" pad="large" fill>
              <Box align="end">
                <Button icon={<FormClose size="36px" color="#000" />} plain onClick={() => this.closeLayer()} />
              </Box>
              <Box className="mobile-nav-list" align="center" gap="large">
                <Anchor href="/" label={this.state.lang == "en" ? "Home" : "Beranda"} />
                <Anchor href="/products" label={this.state.lang == "en" ? "Our Products" : "Produk Kami"} />
                <Anchor href="/inspiration" label="Virtual Assistant" />
                <Anchor href="/articles" label={this.state.lang == "en" ? "Articles" : "Artikel"} />
                <Anchor href="/about-us" label={this.state.lang == "en" ? "About Us" : "Tentang Kami"} />
                <Anchor href="/faq" label="FAQ" />
                { /*
                <Box className="mobile-language-switch" direction="row">
                  <Anchor href="#" label="EN" />
                  <Anchor href="#" label="ID" />
                </Box>
                */ }
              </Box>
            </Box>
          </Layer>
        )
      }
  
      if(this.state.layerMobileNotification){
        return (
          <Layer
            className="mobile-layer"
            full={true}
            onEsc={() => this.closeLayer()}
            position="center"
          >
            <Header className="header-mobile" direction="column" gap="16px" pad={{vertical:"small"}} fill="horizontal">
              <Box className="container" direction="row" align="center" justify="between">
                <Box basis="2/5">
                  <Button icon={<Menu />} plain={true} onClick={() => this.openLayer()} />
                </Box>
                <Box basis="1/5" className="logo" alignSelf="center">
                  <Anchor href="/">
                    <Image
                      src="logo.png"
                    />
                  </Anchor>
                </Box>
                <Nav basis="2/5" direction="row" gap="small" align="center" justify="end">
                  <Anchor href="#" onClick={() => this.openLayerMobile()}>
                    <Image src="icon-notification.png" width="17px" />
                  </Anchor>
                  <Anchor href="/my-account">
                    <Image src="icon-profile.png" width="17px" />
                  </Anchor>
                  <Anchor href="#" onClick={(e) => this.toggleCart()}>
                    <Image src="icon-cart.png" width="17px" />
                  </Anchor>
                </Nav>
              </Box>
            </Header>
            <Box className="drop-notification-mobile">
              <Box direction="row" border={{color:"border", size: "1px", style: "solid", side: "horizontal"}} pad={{vertical:"15px", horizontal:"20px"}} align="center" justify="between">
                <Text weight="bold">Notifications</Text>
                <Button icon={<FormClose color="#757575" />} plain onClick={() => this.closeLayer()} />
              </Box>
              <Box className="drop-notification-list">
                { /* Start Render Notifications  */}
  
                <Box className="notification-item" pad={{horizontal:"20px"}}>
                  <Anchor href="#">
                    <Box direction="row" align="top" gap="small" pad={{vertical:"20px"}}>
                      <StatusGoodSmall color="brand" />
                      <Box gap="xsmall" pad={{top:"4px"}}>
                        <Text size="small" weight="bold" color="brand">Confirm your payment</Text>
                        <Text size="small" color="#000">Invoice no. #971814861 is waiting to be paid.</Text>
                        <Text size="small" color="#737270">03-03-2020 10:46</Text>
                      </Box>
                    </Box>
                  </Anchor>
                </Box>
  
                <Box className="notification-item" pad={{horizontal:"20px"}}>
                  <Anchor href="#">
                    <Box direction="row" align="top" gap="small" pad={{vertical:"20px"}}>
                      <StatusGoodSmall color="brand" />
                      <Box gap="xsmall" pad={{top:"4px"}}>
                        <Text size="small" weight="bold" color="brand">Your order has arrived</Text>
                        <Text size="small" color="#000">Invoice no. #971814861 order has arrived at and is received by <strong>John Doe</strong></Text>
                        <Text size="small" color="#737270">03-03-2020 10:46</Text>
                      </Box>
                    </Box>
                  </Anchor>
                </Box>
                
                { /* End Render Notifications  */}
  
                { /* If there are no notification  */}
                <Box className="notification-item" pad={{horizontal:"20px"}}>
                  <Box gap="xsmall" pad={{vertical:"20px"}}>
                    <Text size="small">No activity yet.</Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Layer>
        )
      }
  }
  
  renderHeaderDesktop(){
    return (
      <Box className="container" direction="row" align="center" justify="start" gap="large">
        <Box className="logo">
          <Anchor href="/">
            <Image
              src="logo.png"
            />
          </Anchor>
        </Box>
        <Box className="header-nav" direction="row" gap="large">
          <Text size="xlarge">Customer Registration Portal</Text>
        </Box>
        <Nav direction="row" gap="small" align="center" justify="end" flex="grow">
          
        </Nav>
      </Box>
    )
  }
  
  renderHeaderMobile(){
    return (
      <>
      <Box className="container" direction="row" align="center" justify="between">
        <Box basis="1/3" direction="row" gap="medium" align="center" justify="start">
          
        </Box>
        <Box basis="1/3" className="logo" align="center">
          <Anchor href="/">
            <Image
              src="logo.png"
            />
          </Anchor>
        </Box>
        <Box basis="1/3">
          
        </Box>
      </Box>
      <Text size="xlarge" align="center" margin={{top: "small"}}>Customer Registration Portal</Text>
      </>
    )
  }
  
  renderHomeBanner(){
    let disp = homebanner.map((dt) => {
      let imgsrc = "";
      let imgsrcmobile = "";
      dt.up_image.forEach((dtx) => {
        if(dtx.type == 'desktop'){
          imgsrc = Config.API_IMG_URL + 'banner/' + dtx.id_image +  '.jpg';
          if(imgsrcmobile.length == 0)
            imgsrcmobile = Config.API_IMG_URL + 'banner/' + dtx.id_image +  '.jpg';
        } else if(dtx.type == 'mobile'){
          imgsrcmobile = Config.API_IMG_URL + 'banner/' + dtx.id_image +  '.jpg';
          if(imgsrc.length == 0)
            imgsrc = Config.API_IMG_URL + 'banner/' + dtx.id_image +  '.jpg';
        }
      });
      return (
      <SwiperSlide>
        <Box classname="home-slide" flex="grow" fill="horizontal">
          <Stack className="stack-wrapper" anchor="center" fill="horizontal">
            <Image fill={true} fit="cover" src={this.state.width > 960 ? imgsrc : imgsrcmobile} />
            { /*
            <Box className="home-slide-content" align="center" gap="small" flex="grow" fill="horizontal">
              <Heading level="1">This is Second Slide</Heading>
              <Paragraph fill>Lorem ipsum dolor sit amet.</Paragraph>
              <Button primary label="Shop Now" />
            </Box>
            */ }
          </Stack>
        </Box>
      </SwiperSlide>
      );
    });
    
    return disp;
  }
  
  render(){
    return (
      <>
        { /* replaced all instances of:
              size !== 'small'
            with
              this.state.width > 960 
        */ }
      
        {this.renderShoppingCart()}
  
        {this.renderNotificationLayer()}
  
        {headertype == "home" &&
          <Stack className="stack-wrapper" anchor="top" fill="horizontal" flex="grow">
            <Swiper
              className="home-slideshow"
              spaceBetween={0}
              effect="fade"
              fadeEffect={{
                "crossFade": true
              }}
              navigation={false}
              pagination={{"clickable": true}}
              loop={true}
              autoplay={{
                "delay": 3500,
                "disableOnInteraction": false,
                "pauseOnMouseEnter": false
              }}
            >
              { /*SwiperSlide>
                <Box classname="home-slide" flex="grow" fill="horizontal">
                  <Stack className="stack-wrapper" anchor="center" fill="horizontal">
                    <Image fill={true} fit="cover" src={this.state.width > 960 ? "home-intro1.jpg" : "home-intro-mobile1.jpg"} />
                    <Box className="home-slide-content" align="center" gap="small" flex="grow" fill="horizontal">
                      <Heading level="1">Your Multipurpose Furniture!</Heading>
                      <Paragraph fill>You can find light, simple & smart furniture for your better living.</Paragraph>
                      <Button primary label="Shop Now" />
                    </Box>
                  </Stack>
                </Box>
              </SwiperSlide>*/ }
              { this.renderHomeBanner() }
            </Swiper>
    
            {( this.state.width > 960) ? (
              <Header className="header-desktop" direction="column" gap="16px" pad={{vertical:"medium"}} fill="horizontal">
                {this.renderHeaderDesktop()}
                { /*
                <Box className="home-announcement" align="center" wrap={true}>
                  <Text color="#757575" weight="bold">Join Our newsletter and get 5% off for your first purchase!</Text>
                </Box>
                */ }
              </Header>
            ): (
              <Header className="header-mobile" direction="column" gap="16px" pad={{vertical:"small"}} fill="horizontal">
                {this.renderHeaderMobile()}
                { /*
                <Box className="home-announcement" align="center" wrap={true}>
                  <Text color="#757575" weight="bold">Join Our newsletter and get 5% off for your first purchase!</Text>
                </Box>
                */ }
              </Header>
            )}
          </Stack>
        }
        
        {headertype == "page" &&
          <>
          {( this.state.width > 960) ? (
              <Box className="page-banner" style={pagebanner == "" ? {height: 0} : {}} background={pagebanner} align="center" flex="grow" fill="horizontal">
                  <Header className="header-desktop" pad={{vertical:"medium"}} fill="horizontal">
                    {this.renderHeaderDesktop()}
                  </Header>
                  {pagetitle}
              </Box>
            ) : (
              <Box className="page-banner" style={pagebannermobile == "" ? {height: 0} : {}} background={pagebannermobile} align="center" flex="grow" fill="horizontal">
                <Header className="header-mobile" pad={{vertical:"small"}} fill="horizontal">
                  {this.renderHeaderMobile()}
                </Header>
                <Box pad={{top: "240px"}} fill="horizontal" alignSelf="start">
                {pagetitle}
                </Box>
              </Box>
            )}
          </>
        }
        
        {headertype == "plain" &&
          <>
          {( this.state.width > 960) ? (
            <Header className="header-desktop" direction="column" gap="16px" pad={{vertical:"medium"}} fill="horizontal">
              {this.renderHeaderDesktop()}
            </Header>
          ): (
            <Header className="header-mobile" direction="column" gap="16px" pad={{vertical:"small"}} fill="horizontal">
              {this.renderHeaderMobile()}
            </Header>
          )}
          </>
        }
      </>
    )
  }
}
  
const mapStateToProps = (state, ownProps) => {
  return {
    login: state.loginData,
    user: state.userData,
    lang: state.langData,
    cart: state.cartData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    switchLang: (lang) => {
      dispatch(switchLang(lang));
    },
    saveLogin: (id_user, api_token) => {
      dispatch(saveLogin(id_user, api_token));
    },
    fetchUser: userdata => {
      dispatch(fetchUser(userdata));
    },
    increaseQuantity: (item) => {
      dispatch(increaseQuantityDispatch(item));
    },
    decreaseQuantity: (item) => {
      dispatch(decreaseQuantityDispatch(item));
    },
    deleteFromCart: (item) => {
      dispatch(deleteFromCartDispatch(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(MainHeader); 
